<template>
  <div class="recognition-page-one">
    <RecognitionPage :backgroundImage="bgImg" :wordObj="wordDetail" />
  </div>
</template>

<script>
import RecognitionPage from "@/components/Course/CoursePage/RecognitionPage";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/12-Vocabulary/lesson-21-ye.svg"),
      wordDetail: {
        pinName: "yě",
        chineseName: "也",
        englishName: "also, too",
        songUrl: require("@/assets/audio/L1/5-Words/ye3.mp3"),
      },
    };
  },
  components: {
    RecognitionPage,
  },
};
</script>

<style lang="scss" scoped>
.recognition-page-one {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>