<template>
  <div class="wordscard-container layout-border">
    <WordCards
      :wordCardList="wordCardList"
      @changeDisplay="handleChangeDisplay"
      :lessonNum="11"
    />
  </div>
</template>
<script>
import WordCards from "@/components/Course/CoursePage/WordCards";

export default {
  components: {
    WordCards,
  },
  data() {
    return {
      wordCardList: [
        {
          bgColor: "redColor",
          order: "one",
          isShowImg: "true",
          currentNum: 1,
          pinyin: "jǐ",
          hanzi: "几",
          english: "how many (within 10)",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-ji-bubble.svg`),
          englishClass: "font-english-medium"
        },
        {
          bgColor: "redColor",
          order: "three",
          isShowImg: "true",
          currentNum: 3,
          pinyin: "suì",
          hanzi: "岁",
          english: "year(s) old",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-sui-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "five",
          isShowImg: "true",
          currentNum: 5,
          pinyin: "duō dà",
          hanzi: "多大",
          english: "how old",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-duoda-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "two",
          isShowImg: "true",
          currentNum: 2,
          pinyin: "yě",
          hanzi: "也",
          english: "also, too",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-ye-bubble.svg`),
        },
        {
          bgColor: "redColor",
          order: "four",
          isShowImg: "true",
          currentNum: 4,
          pinyin: "... duō dà / jǐ suì ?",
          hanzi: "……多大/几岁？",
          english: "How old are/is ... ?",
          englishClass:"font-english-small",
          pinyinClass:"font-english-small",
          image: require(`@/assets/img/12-Vocabulary/lesson-21-duoda-jisui.svg`),
          blue: true,
          longtext: true,
        },
       
       
      ],
    };
  },
  methods: {
    handleChangeDisplay(index, isShowImg) {
      this.wordCardList[index].isShowImg = isShowImg;
    },
  },
};
</script>
<style lang="scss" scoped>
.wordscard-container {
  width: calc(100% - 20px);
  height: calc(100% - 40px);
  &.chapter-wordcard {
    background-color: #fff;
  }
}
</style>
